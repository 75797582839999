import React from 'react';
import { SelectSearch } from '../../../../components/select/SelectSearch';
import { Card } from '../../../../components/Card';
import { usePerfilPublicoContext } from '../../perfil-publico-context';
import { TextField, TextFieldSearch } from '../../../../components/TextField';
import { InputCurrency } from '../../../../components/input/InputCurrency';
import { Delete, Visibility, VisibilityOff } from '@material-ui/icons';
import { useAsyncSelectPaginated } from '../../../../hooks/useAsyncSelectPaginated';
import { LIST_ESPECIALIDADES_MEDICAS } from '../../../../graphql/queryes';
import FormControl from '../../../../components/FormControl';

export function PerfilPublicoAtividade() {
  const { values, setField, errors, unidade, perfilPublico } =
    usePerfilPublicoContext();

  const { loadOptions } = useAsyncSelectPaginated(LIST_ESPECIALIDADES_MEDICAS, {
    dataKey: 'findAllEspecialidadeMedica',
    variables: {
      searchDTO: {
        ativo: true,
      },
      pageableDTO: {
        pageNumber: 0,
        pageSize: 20,
      },
    },
  });

  const handleChangeEspecialidadeField = (index, field, value) => {
    const especialidades = values.especialidades;
    especialidades[index] = {
      ...especialidades[index],
      [field]: value,
    };
    setField('especialidades', [...especialidades]);
  };

  const hasValorOnlineError = (index) => {
    const errorEspecialidade = errors.especialidades?.[index];

    return Boolean(
      (errorEspecialidade && !errorEspecialidade?.valorPresencial) ||
        errorEspecialidade?.valorOnline
    );
  };

  const hasValorPresencialError = (index) => {
    const errorEspecialidade = errors.especialidades?.[index];

    return Boolean(
      (errorEspecialidade && !errorEspecialidade?.valorOnline) ||
        errorEspecialidade?.valorPresencial
    );
  };

  const especialidades = unidade
    ? perfilPublico?.especialidades?.map(
        ({ especialidadeMedica, valorOnline, valorPresencial }) => ({
          id: especialidadeMedica.id,
          descricao: especialidadeMedica.especialidade,
          valorOnline: valorOnline?.toString() || 0,
          valorPresencial: valorPresencial?.toString() || 0,
        })
      )
    : values.especialidades;

  return (
    <>
      <div
        style={{
          display: 'grid',
          width: '100%',
        }}
      >
        {!unidade && (
          <FormControl label="Especialidades">
            <TextFieldSearch
              withPaginate
              loadOptions={loadOptions}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              onChange={(opt) => {
                const isOptionAlreadySelected = values.especialidades.some(
                  (especialidade) => especialidade.id === opt.value
                );

                if (isOptionAlreadySelected) return;

                setField('especialidades', [
                  ...values.especialidades,
                  {
                    id: opt.value,
                    descricao: opt.label,
                    valorOnline: 0,
                    valorPresencial: 0,
                  },
                ]);
              }}
            />
          </FormControl>
        )}
      </div>

      <Card
        style={{
          marginTop: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            fontSize: '10px',
          }}
        >
          <div
            style={{
              fontSize: '10px',
            }}
          >
            Especialidade
          </div>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '110px 110px 50px',
              gap: '10px',
              fontSize: '10px',
            }}
          >
            <div
              style={{
                fontSize: '10px',
              }}
            >
              Valor online
            </div>
            <div
              style={{
                fontSize: '10px',
              }}
            >
              Valor Presencial
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'grid',
            gap: '10px',
          }}
        >
          {especialidades?.map((especialidade, index) => {
            const disabled =
              unidade &&
              !values?.especialidades?.find((e) => e.id === especialidade.id);

            const realIndex = values.especialidades?.findIndex(
              (e) => e.id === especialidade.id
            );

            const specialtyValue = values.especialidades?.find(
              (e) => e.id === especialidade.id
            );

            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                  opacity: unidade ? (disabled ? 0.5 : 1) : 1,
                }}
              >
                <div>{especialidade.descricao}</div>

                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '110px 110px 50px',
                    gap: '10px',
                    fontSize: '10px',
                  }}
                >
                  <InputCurrency
                    value={specialtyValue?.valorOnline || null}
                    error={hasValorOnlineError(realIndex)}
                    disabled={disabled}
                    onChange={(e) => {
                      handleChangeEspecialidadeField(
                        realIndex,
                        'valorOnline',
                        e.target.value
                      );
                    }}
                  />

                  <InputCurrency
                    value={specialtyValue?.valorPresencial || null}
                    error={hasValorPresencialError(realIndex)}
                    disabled={disabled}
                    onChange={(e) => {
                      handleChangeEspecialidadeField(
                        realIndex,
                        'valorPresencial',
                        e.target.value
                      );
                    }}
                  />

                  <button
                    style={{
                      border: 'none',
                      background: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (unidade && disabled) {
                        return setField('especialidades', [
                          ...values.especialidades,
                          especialidade,
                        ]);
                      }

                      const especialidades = [...values.especialidades];
                      especialidades.splice(realIndex, 1);
                      setField('especialidades', [...especialidades]);
                    }}
                  >
                    {unidade ? (
                      <>{disabled ? <Visibility /> : <VisibilityOff />}</>
                    ) : (
                      <Delete />
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </Card>
    </>
  );
}
